.reports-section {
  padding-top: 150px;
}

.upersection {
  position: relative;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 26px;
}

.container .leftSection {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: baseline;
}

.container .leftSection .filterText {
  display: flex;
  gap: 10px;
}

.container .rightSection {
  width: 75%;
}

.upersection::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 0.5px;
  width: 100%;
  background-color: #b7b4b4;
}

.lowersection {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 30px;
}

.leftFilter {
  width: 25%;
  box-shadow: 0.5px 0 0 0 #b7b4b4;
  /* height: 100vh;
    overflow-y: auto; */
}

.filters > div {
  padding-bottom: 20px;
  position: relative;
}

.leftFilter > .filters > div::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.5px;
  background-color: #b7b4b4;
}

.rightReports {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  height: 171vh;
}

.rightReports::-webkit-scrollbar {
  display: none;
}

.reports {
  display: flex;
  margin: 30px 50px;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.year,
.author,
.category,
.subCategory {
  padding: 30px;
}

#search-input {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

#search-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
}

#search-button:hover {
  background-color: #0056b3;
}

.searchBox {
  width: 80%;
  margin: 0 auto;
}

.yearCheckbox,
.authorCheckbox,
.sectorCheckbox,
.subsectorCheckbox {
  display: flex;
  align-items: center;
  gap: 12px;
}

.yearCheckbox input,
.authorCheckbox input,
.sectorCheckbox input,
.subsectorCheckbox input {
  margin-top: 0 !important;
}

.yearData,
.authorsData,
.categoryData,
.subCategoryData {
  margin-top: 12px;
}

.year h4,
.author h4,
.category h4,
.subCategory h4 {
  font-weight: bold;
}

input[type="checkbox"] {
  height: 16px !important;
  width: 16px !important;
  border: 1px solid #c3c2c9 !important;
  background: #fff !important;
  border-radius: 2px !important;
}

.loadmore {
  padding: 12px 0px 0px 26px;
}

.loadmore span {
  cursor: pointer;
  color: #4343ff;
  font-weight: 500;
}

.author {
  position: absolute;
}

.popup {
  position: relative;
  top: -210px;
  left: -33px;
  width: 850px;
  height: 100%;
  display: flex;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.253);
}

.popup-content {
  background: white;
  padding: 20px;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-popup {
  position: absolute;
  top: -10px;
  right: -55px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

.popup-authors,
.popup-sectors {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  justify-items: start;
  align-items: start;
}

.popup-authorCheckbox,
.popup-sectorCheckbox,
.popup-yearCheckbox {
  display: flex;
  align-items: center;
}

.popup-authorCheckbox input,
.popup-sectorCheckbox input {
  margin-right: 10px;
  margin-top: 0px !important;
}

.pagination {
  display: flex;
  justify-content: center;
}

.loader-section {
  background: white;
  z-index: 99999;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 70px;
}

.loader {
  top: 40%;
  left: 50%;
  position: absolute;
}

.report-loader {
    top: 20%;
    left: 60%;
    position: absolute;
}

.container .rightSection {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.container .rightSection .searchSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76% !important;
}

.container .rightSection .searchSection input {
  width: 100% !important;
  padding: 12px 12px;
  margin-top: 0px;
  margin-right: -12px;
}

.mobileBar,
.searchPopup {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.no-more-reports a {
  color: #4343ff;
  text-decoration: none;
  font-weight: 700;
}

.no-more-reports {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  margin: 20px 0;
  gap: 5px;
}

.no-more-reports::before,
.no-more-reports::after {
  content: "";
  flex-grow: 1;
  border-bottom: 2px solid #000;
  margin: 0 10px;
  width: 60px;
}

.clearBtn {
  font-size: 14px;
  font-weight: 500;
  color: #4343ff;
  cursor: pointer;
  text-decoration: underline;
}

.searchSection .clearBtn {
  position: absolute;
  left: 85%;
}

.searchNotFound {
  display: flex;
  flex-direction: column;
}

.searchNotFound p {
  margin: 0 auto;
}

.searchQuery {
  padding: 0;
}

.searchQueryText {
  color: #4343ff;
  font-weight: bold;
}

.searchEmail,
.searchQuery {
  border: none;
  border-bottom: 1px solid black;
}

.searchEmail:focus,
.searchQuery:focus { 
  border: none; 
  outline: none;
  border-bottom: 1px solid black;
}

.mailMeBtn {
  margin: 0 auto;
  margin-top: 20px;
}

.snackbar {
  position: fixed;
  background-color: #4343ff;
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 10px 10px;
  top: 12%;
  left: 50%;
  z-index: 1000;
  border: 0.5px solid black;
}

.close-button {
  font-size: 18px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .leftFilter {
    display: none;
  }
  .container .leftSection {
    display: none;
  }

  .lowersection {
    justify-content: center;
  }

  .reports-section {
    padding-top: 80px;
  }

  .container .rightSection {
    width: 100%;
    justify-content: start;
  }

  .rightReports {
    width: 100%;
}

  .upersection {
    display: none;
  }

  .searchPopup {
    display: flex;
    position: fixed;
    height: 70px;
    bottom: 70px;
    background-color: white;
    width: 100%;
    box-shadow: 0px 0px 10px rgb(110, 110, 110);
    z-index: 99;
    justify-content: center;
    align-items: center;
  }

  .searchInput {
    width: 90%;
  }

  .searchInput .searchSectionMobile {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .searchInput .searchSectionMobile .clearBtn {
    position: absolute;
    right: 7%;
  }

  .searchInput .searchSectionMobile input {
    padding: 6px 12px;
    margin-top: 0px;
    border-radius: 0px;
    width: 90%;
  }

  .mobileFilterPopup {
    position: fixed;
    height: 100%;
    background-color: white;
    width: 100%;
    z-index: 99;
    overflow: auto;
    top: 70px;
  }

  .filterScreen {
    padding-top: 0px !important;
    display: flex;
    height: 100%;
  }

  .mobileBar {
    display: flex;
    position: fixed;
    height: 70px;
    width: 100%;
    background-color: black;
    color: white;
    bottom: 0;
    z-index: 9999;
    align-items: center;
    justify-content: space-around;
  }

  .mobileBarLeft,
  .mobileBarRight {
    flex: 1;
    text-align: center;
    cursor: pointer;
  }

  .mobileBarDivider {
    width: 0.5px;
    height: 20px; /* Adjust height as needed */
    background-color: white;
  }

  .filterHeading {
    display: flex;
    flex-direction: column;
    width: 30%;
    background-color: #f5f5f6;
    padding-top: 2px;
    height: 100%;
  }

  .filterHeading span {
    border-bottom: 1px solid #8383834e;
    padding: 15px 10px;
    font-size: 13px;
  }

  .filterData {
    padding: 0px 20px;
    width: 70%;
    overflow: auto;
    position: relative;
    height: 100%;
  }

  .year,
  .author,
  .category,
  .subCategory {
    padding: 1px 15px;
    padding-bottom: 140px;
  }

  .yearData,
  .authorsData,
  .categoryData,
  .subCategoryData {
    margin-top: 0px;
  }

  .author {
    padding-bottom: 100px !important;
    width: 75%;
  }

  .no-more-reports {
    display: block;
    align-items: center;
    text-align: center;
    font-size: 16px;
    margin: 20px;
    gap: 5px;
  }

  .no-more-reports::before,
  .no-more-reports::after {
    content: "";
    flex-grow: 1;
    border-bottom: 2px solid #000;
    margin: 0;
    width: 0px;
  }

  .filterHeading .selected {
    background-color: white; /* Change to desired color */
    font-weight: bold;
  }

  .popup-authorCheckbox,
  .popup-sectorCheckbox,
  .yearCheckbox,
  .subsectorCheckbox {
    padding: 15px 0px;
    font-size: 13px;
    border-bottom: 1px solid #8383834e;
  }

  .report-loader {
    top: 35%;
    left: 45%;
    position: absolute;
  }

  .mobileLoader {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: white;
    z-index: 1000;
    margin-top: -10px;
  }

  .snackbar {
    padding: 10px 10px;
    top: 11%;
    left: 0%;
    font-size: 12px;
    width: 300px;
    gap: 5px;
  }

  .searchQuerySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}
