.faq {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.faq h2 {
  text-align: center;
  margin-bottom: 20px;
}

.faq-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #4343ff;
}

.faq-question span {
  font-weight: 600;
  font-size: 18px;
}

.faq-icon {
  font-size: 24px;
}

.faq-answer {
  padding: 10px 0;
  font-size: 16px;
  color: #555;
}

@media (max-width: 768px) {
  .faq-question span {
    font-size: 16px;
  }
}
