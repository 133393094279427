@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
}

button {
  font-family: "Nunito Sans", sans-serif;
}

h1 {
  font-weight: 600;
}

h4,
span {
  font-weight: 300;
}

.Whatsapp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Whatsapp img {
  width: 50px;
}

.community {
  position: fixed;
  bottom: 5%;
  right: 2%;
  background-color: white;
  padding: 12px;
  z-index: 99999;
  border: 1px solid #4a4a4a76;
  width: 250px;
  gap: 10px;
}

.community span.close-btn {
  position: absolute;
  top: -3%;
  right: 3%;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 768px) {
  .community {
    bottom: 14%;
    right: 2%;
    font-size: 10px;
    width: 150px;
    padding: 4px;
  }

  .Whatsapp img {
    width: 30px;
  }
}
