.landingPage {
  background-color: #faf9f6;
  width: 100%;
  height: 100vh;
}






/* Our Members section  */

.ourMembers {
  padding-top: 60px;
}

.ourMembers h1 {
  text-align: center;
}

.members {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  margin: 40px 0;
  padding-bottom: 20px;
}

.member img {
  width: 300px;
  border-radius: 9px;
}

.member {
  position: relative;
}

.member h3,
.member p {
  text-align: center;
}

/* Featured Reports */

.featuredReports h2 {
  text-align: center;
}

.featuredReports {
  margin: 40px 0px;
  overflow-x: hidden;
}

.container {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  padding-top: 30px;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .effectWrap {
    display: none;
  }
  .container {
    flex-direction: column-reverse;
    padding-top: 10px;
  }

  .landingPage .container .left h1 {
    font-size: 18px;
    padding-top: 30px;
    text-align: center;
  }

  .left button {
    display: none;
  }

  .landingPage .container .left h1 {
    width: auto;
  }

  .landingPage {
    height: auto;
  }

  .featuredReports h2,
  .ourMembers h1 {
    font-size: 20px;
  }

  .member img {
    width: 210px;
  }
}
