
.upperSection {
    width: 100%;
    height: 75vh;
    background-color: rgb(255, 165, 165)
}

.lowerSection {
    display: flex;
    padding: 100px 50px;
    padding-bottom: 10px;
    justify-content: flex-start; /* Change from space-around to flex-start */
    margin-bottom: 30px;
}

.filters {
    flex: 0 0 300px; /* Set a fixed width for the filters div */
}


.filters input.search {
    margin-top: 10px;
    width: 170px;
    padding: 12px 12px;
    outline: none;
    border: none;
    background-color: rgb(230, 230, 230);
    border-radius: 8px;
    box-shadow: 3px 3px 10px rgba(107, 107, 107, 0.326);
    
}

.categories, .authors {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.subcategories {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.viewMoreButton {
    margin-top: 10px;
    background-color: #FAF9F6;
    border: none;
    cursor: pointer;
    color: blue;
    font-size: 15px;
    margin-left: -80px;
}
 
.reports {
    flex-grow: 1;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding: 0 42px;
}

.yearFilter {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.yearFilter select {
    margin-top: 10px;
    width: 192px;
    padding: 12px 12px;
    outline: none;
    border: none;
    background-color: rgb(230, 230, 230);
    border-radius: 8px;
    box-shadow: 3px 3px 10px rgba(107, 107, 107, 0.326);
}

.filterLabel {
    font-weight: bold;
    margin-bottom: 10px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.pagination button {
    padding: 10px 20px;
    background-color: #F9C22E;
    border: none;
    cursor: pointer;
}

.pagination span {
    padding: 6.3px 20px; 
    border: 1px solid#F9C22E;
}

@media screen and (max-width: 768px) {
    .filters {
        margin-bottom: 50px;
    }

    .reports {
        padding: 0;
    }

    .lowerSection {
        padding: 50px 28px;
        flex-direction: column;
        padding-bottom: 10px;
    }

    .filters input.search {
        width: 230px;
    }

    .yearFilter select {
        width: 250px;
    }

    .categories button {
        margin-left: -152px;
    }
    
}

.loader {
    top: 40%;
    left: 50%;
    position: absolute;
}