.reportInfo h1 {
    font-size: 24px;
}

.reportInfoStrip {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4343FF;
    color: white;
    flex-direction: column;
    margin-bottom: 18px;
    position: fixed;
    width: 100%;
    z-index: 99;
}

.reportInfoStrip h1 {
    font-size: 14px;
}

.reportInfoStrip p {
    font-size: 10px;
}

.reportDetails {
    padding-top: 70px;
}

.report-frame {
    margin-bottom: 20px;
    margin-top: 70px;
}

.simmilarReport {
    width: 1200px;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 70px;
}

.simmilarReport h1 {
    margin-bottom: 20px;
}

.pdfViewer {
    width: 100%;
    margin: 20px 0;
  }

  .pdfDownload {
    margin: 0px 10px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pdfDownload span {
    color: #4343FF;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .pdfViewer {
        height: 100%;
    }
    div[data-testid="core__inner-pages"] {
        width: 100%;
        overflow: hidden !important;
      }
      
     
  }
  